import {
	action,
	decorate,
	observable,
	runInAction,
} from "mobx";

export class ExternalCategoryPageController {

	rootId = null;
	error = false;
	loading = true;

	categories = []
	categoryStore = null

	constructor(rootStore) {
		this.categoryStore = rootStore.categoryStore;
	}

	getCategory(id) {

		let category = null;

		const lookup = (c) => {

			if (c.categoryId === id) {
				category = c;
				return;
			}

			if (c.subCategories !== null) {
				c.subCategories.forEach(lookup);
			}

		};

		this.categories.forEach(lookup);

		if (!category) {
			return { subCategories: this.categories };
		}

		return category;
	}

	getCategoryStack(path) {

		const params = path.split("/");

		const id = parseInt(params[params.length - 1], 10);

		if (Number.isNaN(id)) {
			return [];
		}

		const stack = [];

		const pushCategory = (cid) => {

			const currentCategory = this.getCategory(cid);

			if (currentCategory) {
				stack.push(currentCategory);
			}

			if (currentCategory && currentCategory.parentId) {
				pushCategory(currentCategory.parentId);
			}

		};

		pushCategory(id);

		return stack.reverse();

	}

	async getCategories() {
		try {
			const categories = await this.categoryStore.getExternalCategories();

			if (categories) {
				runInAction(() => this.categories = observable(categories));
			}

			runInAction(() => {
				this.error = false;
				this.loading = false;
			});
		} catch (e) {
			runInAction(() => {
				this.error = true;
				this.loading = false;
			});
		}
	}
}

decorate(ExternalCategoryPageController, {
	error: observable,
	rootId: observable,
	loading: observable,

	categories: observable,
	getCategories: action,
});
