import React from "react";
import { observer, inject } from "mobx-react";

import { Button, ListGroup } from "react-bootstrap";

import { AttachmentController } from "../../controllers";
import Spinner from "./Spinner";

const Attachments = observer(
	class extends React.Component {
		constructor(props) {
			super(props);

			this.controller = new AttachmentController(props.rootStore);
			this.issueId = props.issueId;
			this.attachmentCount = props.attachmentCount;
		}

		showDocuments() {
			this.controller.getDocuments(this.issueId);
		}

		renderDocuments() {
			return this.controller.documents.map((document) => (
				<ListGroup.Item
					key={document.id}
					action
					onClick={() => this.controller.getDocument(document, this.issueId)}
				>
					{document.fileName}
				</ListGroup.Item>
			));
		}

		render() {
			return (
				<>
					<Button className="mt-2" onClick={() => this.showDocuments()}>
            Visa bifogade filer
					</Button>
					<ListGroup>
						{this.controller.loading && <Spinner />}
						{!this.controller.loading && this.renderDocuments()}
					</ListGroup>
				</>
			);
		}
	},
);

export default inject("rootStore")(Attachments);
