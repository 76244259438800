import {
	action,
	decorate,
	computed,
	observable,
	runInAction,
} from "mobx";

const userKey = "user";
const tokenKey = "token";

export class UserStore {

	token = ""
	user = null
	loading = false

	constructor(rootStore) {

		this.rootStore = rootStore;

		const token = localStorage.getItem(tokenKey);

		if (token !== null) {
			this.token = token;
		}

		const user = localStorage.getItem(userKey);

		if (user !== null) {
			try {
				this.user = JSON.parse(user);
			} catch (e) {
				this.user = null;
				this.token = null;
			}
		}

	}

	get isAuthenticated() {
		return this.user !== null;
	}

	getTokenHeader() {
		return { Authorization: `Bearer ${this.token}` };
	}

	getAuthQueryStrings() {
		return `&redirect_uri=${encodeURIComponent(window.location.origin + "/auth")}`;
	}

	async login() {

		this.loading = true;

		const url = await this.rootStore.network.getLoginUrl();

		runInAction(() => this.loading = false);

		window.location.href = url + this.getAuthQueryStrings();

	}

	async auth(code) {

		const body = await this.rootStore.network.auth(code, this.getAuthQueryStrings());
		body.user.issueFilter = {
			search: "",
			status: [{ value: "open", label: "Öppna" }],
			type: [],
			area: [],
			onlyAttachment: false,
			sortBy: { value: "createDate", label: "Datum skapat" },
			sortOrder: { value: "desc", label: "Stigande" },
		};

		this.user = body.user;
		localStorage.setItem(userKey, JSON.stringify(body.user));

		this.token = body.token;
		localStorage.setItem(tokenKey, this.token);
		const path = localStorage.getItem("redirectUri") ?? "/";
		return path;
	}

	async verifyToken() {
		if (!this.isAuthenticated) {
			return;
		}

		const response = await this.rootStore.network.verifyUserToken(this.getTokenHeader());
		// If Unauthorized log the user out
		if (response.status === 401) {
			this.logout();
		}
	}

	logout() {
		this.token = "";
		this.user = {};
		localStorage.clear();
		window.location.href = "/";
	}

	updateUser() {
		localStorage.setItem(userKey, JSON.stringify(this.user));
	}
}

decorate(UserStore, {
	user: observable,
	token: observable,
	loading: observable,

	login: action,
	logout: action,
	verifyToken: action,
	updateUser: action,

	isAuthenticated: computed,
});
