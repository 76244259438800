import React from "react";
import { observer, inject } from "mobx-react";

import { Row, Breadcrumb } from "react-bootstrap";

import { Route } from "react-router-dom";

import { isForm } from "../../util";

import { Page, Footer, Spinner } from "../common";

import FormPage from "./FormPage";
import { SubCategoryPage } from "./SubCategoryPage";
import { CategoryPageController } from "../../controllers";

const CategoryPage = observer(
	class extends React.Component {
		constructor(props) {
			super(props);

			this.controller = new CategoryPageController(props.rootStore);
		}

		async componentDidMount() {
			await this.controller.getCategories();
		}

		onRef(path) {
			const { history } = this.props;
			history.push(path);
		}

		renderContent() {
			if (this.controller.loading) {
				return (
					<Row className="justify-content-md-center">
						<Spinner size="lg" />
					</Row>
				);
			}

			return (
				<>
					<Route
						exact
						path="/category"
						render={() => (
							<SubCategoryPage isRoot controller={this.controller} />
						)}
					/>

					<Route
						exact
						path="/category/:id"
						render={() => <SubCategoryPage controller={this.controller} />}
					/>

					<Route
						exact
						path="/category/:id/form/:formId"
						render={() => <FormPage pageController={this.controller} />}
					/>
				</>
			);
		}

		renderBreadcrumbs() {
			if (this.controller.loading) {
				return null;
			}

			const { pathname } = this.props.location;

			const categoryStack = this.controller.getCategoryStack(pathname);

			const items = categoryStack.map((c) => {
				const path = isForm(c)
					? `/category/${c.parentId}/form/${c.categoryId}`
					: `/category/${c.categoryId}`;

				return (
					<Breadcrumb.Item key={c.categoryId} onClick={() => this.onRef(path)}>
						{c.title}
					</Breadcrumb.Item>
				);
			});

			return (
				<Breadcrumb style={{ marginBottom: "-15px" }}>
					<Breadcrumb.Item key={0} onClick={() => this.onRef("/category")}>
            Registrera ärenden
					</Breadcrumb.Item>
					{items}
				</Breadcrumb>
			);
		}

		render() {
			return (
				<Page footer={<Footer />}>
					{this.renderBreadcrumbs()}

					{this.renderContent()}
				</Page>
			);
		}
	},
);

export default inject("rootStore")(CategoryPage);
