import {
	toJS,
	action,
	decorate,
	observable,
	runInAction,
	computed,
} from "mobx";

import {
	isFAQ,
	isDocument,
	isDynamicDropDown,
	isInfoText,
	isUrl,
} from "../util";

import { createFormFields } from "../models";

export const formStates = {
	none: "none",
	error: "error",
	loading: "loading",
	success: "success",
};

export class FormController {
  id = null;
  title = null;
  userStore = null;
  issueStore = null;

  faqFields = [];
  formFields = [];
  formState = formStates.none;
  formErrorMessage = null;

  constructor(rootStore, id, title, formFields) {
  	this.id = id;
  	this.title = title;
  	this.userStore = rootStore.userStore;
  	this.issueStore = rootStore.issueStore;

  	const [filteredFields, faqFields] = formFields.reduce(
  		([fields, faqs], next) => (isFAQ(next.type)
  				? [fields, [...faqs, next]]
  				: [[...fields, next], faqs]),
  		[[], []],
  	);

  	this.formFields = observable(createFormFields(filteredFields));

  	this.faqFields = faqFields.map((f) => ({
  		id: f.categoryId,
  		title: f.title,
  		description: f.placeholder,
  	}));
  }

  validateForm() {
  	let isValid = true;

  	this.formFields.forEach((f) => {
  		if (isDynamicDropDown(f.type)) {
  			f.dynamicFields
  				.filter((df) => f.selectedOption === df.option)
  				.forEach((df) => {
  					if (!df.validate().valid) {
  						isValid = false;
  						df.onBlur();
  					}
  				});
  		}

  		if (!f.validate().valid) {
  			isValid = false;
  			f.onBlur();
  		}
  	});

  	return isValid;
  }

  async onSend() {
  	try {
  		this.formState = formStates.loading;

  		const formFields = this.formFields
  			.filter(
  				(f) => !isDocument(f.type)
            && !isInfoText(f.type)
            && !isDynamicDropDown(f.type),
  			)
  			.map((f) => f.json());

  		this.formFields
  			.filter((f) => isDynamicDropDown(f.type))
  			.forEach((f) => {
  				f.dynamicFields
  					.filter((df) => f.selectedOption === df.option)
  					.forEach((df) => {
  						df.isDynamic = true;
  						formFields.push(df.json());
  					});
  			});

  		const id = await this.issueStore.addIssue({
  			categoryId: this.id,
  			formFields,
  		});

  		const filePromises = this.formFields
  			.filter((f) => isDocument(f.type))
  			.map((f) => this.issueStore.addIssueDocument(id, toJS(f.files)));

  		filePromises.push(
  			this.formFields
  				.filter((f) => isDynamicDropDown(f.type))
  				.forEach((f) => {
  					f.dynamicFields
  						.filter((df) => f.selectedOption === df.option)
  						.filter((df) => isDocument(df.type))
  						.map((df) => this.issueStore.addIssueDocument(id, toJS(df.files)));
  				}),
  		);

  		await Promise.all(filePromises);

  		runInAction(() => (this.formState = formStates.success));

  		return true;
  	} catch (e) {
  		const errorMessage = JSON.parse(e.message);

  		runInAction(() => (this.formState = formStates.error));
  		runInAction(() => (this.formErrorMessage = errorMessage.message));

  		return false;
  	}
  }

  get isLinkOnlyForm() {
  	if (this.formFields.length === 1) {
  		if (isUrl(this.formFields[0].type)) {
  			return true;
  		}
  	}
  	return false;
  }

  get isInfoOnlyForm() {
  	let response = true;
  	this.formFields.forEach((field) => {
  		if (!isUrl(field.type) && !isInfoText(field.type)) {
  			response = false;
  		}
  	});
  	return response;
  }
}

decorate(FormController, {
	onSend: action,
	formState: observable,
	formFields: observable,
	formErrorMessage: observable,
	isLinkOnlyForm: computed,
	isInfoOnlyForm: computed,
});
