import {
	action, decorate, computed, observable,
} from "mobx";

import {
	validators,
	isDocument,
	isInfoText,
	isUrl,
	isDynamicDropDown,
} from "../util";

const ignoredType = (type) => isDocument(type) || isInfoText(type) || isUrl(type);

export class FormField {
  id = null;
  type = null;
  title = null;
  required = false;
  sortOrder = null;
  placeholder = null;

  option = null;
  options = [];
  validators = [];

  _value = null;
  _valid = false;

  isDynamic = false;
  error = "";
  touched = false;

  constructor(formField, value) {
  	this._value = value;

  	this.id = formField.id;
  	this.type = formField.type;
  	this.title = formField.title;
  	this.sortOrder = formField.sortOrder;
  	this.placeholder = formField.placeholder;
  	this.option = formField.option;

  	this.required = formField.required;

  	if (formField.options) {
  		this._value = this.placeholder;
  		this.options = formField.options.split(",");
  	}

  	if (formField.validators.length > 0) {
  		this.validators = formField.validators;
  	}
  }

  get value() {
  	return this._value;
  }

  get valid() {
  	return !this.touched || this._valid;
  }

  onChange(value) {
  	this._valid = true;
  	this._value = value;
  }

  onBlur() {
  	this.touched = true;
  	const result = this.validate();

  	this._valid = result.valid;
  	this.error = result.error;
  }

  validate() {
  	if (ignoredType(this.type)) {
  		return {
  			valid: true,
  			error: "",
  		};
  	}

  	if (this.required && isDynamicDropDown(this.type) && !this.selectedOption) {
  		return {
  			valid: false,
  			error: "Du måste välja ett alternativ...",
  		};
  	}

  	if (this.required) {
  		const result = validators.required(this.value);

  		if (!result.valid) {
  			return result;
  		}
  	}

  	for (let i = 0; i < this.validators.length; i += 1) {
  		const [name, option] = [
  			this.validators[i].name,
  			this.validators[i].option,
  		];

  		const validator = validators[name];

  		if (validator && typeof validator === "function") {
  			const result = validator(this.value, option);

  			if (!result.valid) {
  				return result;
  			}
  		}
  	}

  	return {
  		valid: true,
  		error: "",
  	};
  }

  json() {
  	return {
  		id: this.id,
  		title: this.title,
  		value: this.value,
  		isDynamic: this.isDynamic,
  	};
  }
}

decorate(FormField, {
	onBlur: action,
	onChange: action,
	validate: action,

	value: computed,
	valid: computed,

	_valid: observable,
	_value: observable,
	error: observable,
	touched: observable,
});
