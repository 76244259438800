import React from "react";
import { observer, inject } from "mobx-react";
import parse from "html-react-parser";

import { Form, Button } from "react-bootstrap";

import Spinner from "./Spinner";

import "./IssueComments.css";
import { IssueCommentsController } from "../../controllers";

const IssueComments = observer(
	class extends React.Component {
		constructor(props) {
			super(props);

			this.controller = new IssueCommentsController(props.rootStore, props.issue);
			this.controller.getComments();
		}

		onSend = (e) => {
			e.preventDefault();
			this.controller.postComment();
		};

		setComment = (e) => {
			this.controller.setComment(e.currentTarget.value);
		};

		render() {
			return (
				<>
					<h4>Kommentarer</h4>
					
					{this.controller.loading && (
						<div className="mb-2">
							<Spinner /> Hämtar kommentarer
						</div>
					)}
					{this.controller.comments
						.sort((a, b) => {
							const aDate = new Date(a.eventDate);
							const bDate = new Date(b.eventDate);

							if (aDate < bDate) return 0;
							if (aDate > bDate) return -1;

							return 1;
						})
						.map((comment, index) => {
							const dateTime = new Date(comment.eventDate);

							return (
								<div className="comment" key={index}>
									<div className="comment-title">
										{comment.name === "Slutanvändare"
											? this.controller.userStore.user.name
											: comment.name}
										<div className="comment-title-date">
											{dateTime.toLocaleString()}
										</div>
									</div>
									<div className="comment-text">
										{parse(comment.eventText)}
									</div>
								</div>
							);
						})}
						{!this.controller.issueIsClosed && (
						<Form onSubmit={(e) => this.onSend(e)} className="comment-form">
							<Form.Row>
								<Form.Control
									as="textarea"
									rows={3}
									onChange={this.setComment}
									value={this.controller.comment}
									disabled={this.controller.commentSend}
									required
								/>
							</Form.Row>
							<Form.Row className="d-flex justify-content-end mt-2">
								<Button
									type="submit"
									variant="primary"
									disabled={this.controller.commentSend}
								>
									{!this.controller.commentSend && this.controller.commentButton}
									{this.controller.commentSend && <Spinner />}
								</Button>
							</Form.Row>
						</Form>
					)}
				</>
			);
		}
	},
);

export default inject("rootStore")(IssueComments);
