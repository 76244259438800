import "./index.css";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as serviceWorker from "./serviceWorker";

import { App } from "./App";
import { Network } from "./network";
import { RootStore } from "./stores/RootStore";

console.log("ENVIRONMENT:", process.env.REACT_APP_SENTRY_ENVIRONMENT);

Sentry.init({
	dsn: "https://16ea14c6b0d74046bb80da8e09e95be1@o455581.ingest.sentry.io/6039333",
	integrations: [new Integrations.BrowserTracing()],
	environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "unknown",
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 0.5,
});

ReactDOM.render(
	<Provider rootStore={new RootStore(new Network())}>
		<App />
	</Provider>,
	document.getElementById("root"),
);

serviceWorker.unregister();
