export function chunk(array = [], size = 0) {

	const chunks = [];

	let index = 0;

	while (index < array.length) {
		chunks.push(array.slice(index, (index += size)));
	}

	return chunks;

}
