import {
	action,
	decorate,
	observable,
	runInAction,
	toJS,
} from "mobx";

export class AttachmentController {
	error = false
	loading = false

	documents = [];

	constructor(rootStore) {
		this.issueStore = rootStore.issueStore;
		this.userStore = rootStore.userStore;
	}

	async getDocuments(issueId) {
		this.loading = true;

		try {
			const documents = await this.issueStore.getIssueDocuments(issueId);

			if (documents) {
				this.documents = documents;
			}

			runInAction(() => {
				this.error = false;
				this.loading = false;
			});
		} catch (e) {
			runInAction(() => {
				this.error = true;
				this.loading = false;
			});
		}
	}

	async getDocument(document, issueId) {
		this.loading = true;

		try {
			await this.issueStore.getIssueDocument(document, issueId);

			runInAction(() => {
				this.error = false;
				this.loading = false;
			});

		} catch (e) {
			runInAction(() => {
				this.error = true;
				this.loading = false;
			});
		}
	}

	async addNewIssueDocuments(issueId) {
		runInAction(() => {
			this.loading = true;
		});

		try {
			await this.issueStore.addIssueDocument(issueId, toJS(this.documents));

			runInAction(() => {
				this.loading = false;
			});

			return true;

		} catch (e) {
			runInAction(() => {
				this.error = true;
				this.loading = false;
			});

			return false;
		}
	}
}

decorate(AttachmentController, {
	error: observable,
	loading: observable,

	documents: observable,
	downloadLink: observable,
	getDocuments: action,
});
