import {
	action, decorate, observable, computed, runInAction,
} from "mobx";

export class IssuePageController {
  error = false;
  errorMessage = null;
  loading = false;

  issue = null;
  issues = [];
  issueTypes = [];
  issueAreas = [];
  issueStatuses = [
  	{ value: "", label: "STATUS", isDisabled: true },
  	{ value: "preclosed", label: "Klart för stängning" },
  	{ value: "closed", label: "Stängda" },
  	{ value: "open", label: "Öppna" },
  ];
  issueSortBy = [
  	{ value: "", label: "SORTERA EFTER", isDisabled: true },
  	{ value: "createDate", label: "Datum skapat" },
  	{ value: "completionDate", label: "Datum avslutat" },
  	{ value: "issueKey", label: "Ärendenummer" },
  ];
  issueSortOrder = [
  	{ value: "", label: "SORTERINGSORDNING", isDisabled: true },
  	{ value: "asc", label: "Stigande" },
  	{ value: "desc", label: "Fallande" },
  ];
  issueFilter = {};
  years = [];

  constructor(rootStore) {
  	this.issueStore = rootStore.issueStore;
  	this.userStore = rootStore.userStore;

  	this.generateArrayOfYears();

  	const issueFilter = this.userStore.user?.issueFilter ?? {
  		search: "",
  		status: [],
  		type: [],
  		area: [],
  		onlyAttachment: false,
  		sortBy: "createDate",
  		sortOrder: "desc",
  		year: new Date().getFullYear(),
  	};
  	this.issueFilter = observable(issueFilter);

  	if (this.issueStore.issues.length > 0) {
  		this.issues = this.issueStore.issues;
  	}
  }

  generateArrayOfYears = () => {
  	let current = new Date().getFullYear();
  	const min = 2020;
  	this.years = [];
  	do {
  		this.years.push({
  			value: current.toString(),
  			label: `År ${current.toString()}`,
  		});
  		current--;
  	} while (current >= min);
  };

  async getIssue(issueKey) {
  	this.loading = true;
  	try {
  		const issue = await this.issueStore.getIssue(issueKey);

  		if (issue === 204) {
  			runInAction(() => {
  				this.error = true;
  				this.errorMessage = "Ärendet kunde inte hittas, du kommer strax bli skickad till Mina Ärenden";
  				this.loading = false;
  				setTimeout(() => {
  					window.location.href = "/issues";
  				}, 5000);
  			});
  			return;
  		}

  		if (issue) {
  			runInAction(() => {
  				this.issue = observable(issue);
  			});
  		}

  		runInAction(() => {
  			this.error = false;
  			this.loading = false;
  		});
  	} catch (e) {
  		runInAction(() => {
  			this.error = true;
  			this.loading = false;
  		});
  	}
  }

  async getIssues(year) {
  	this.loading = true;

  	if (!year && year === 0) {
  		year = new Date().getFullYear();
  	}

  	try {
  		const issues = await this.issueStore.getIssues(year);
  		const types = [{ value: "", label: "ÄRENDETYP", isDisabled: true }];
  		const areas = [{ value: "", label: "AFFÄRSOMRÅDE", isDisabled: true }];
  		issues.forEach((issue) => {
  			const type = {
  				value: issue.type,
  				label: issue.type,
  			};
  			if (types.filter((t) => t.value === type.value).length === 0) types.push(type);

  			const area = {
  				value: issue.issueKey.split("-")[0],
  				label: issue.issueKey.split("-")[0],
  			};
  			if (areas.filter((a) => a.value === area.value).length === 0) areas.push(area);
  		});

  		if (issues) {
  			runInAction(() => {
  				this.issues = observable(issues);
  				this.issueTypes = observable(types);
  				this.issueAreas = observable(areas);
  			});
  		}

  		runInAction(() => {
  			this.error = false;
  			this.loading = false;
  			this.commentSend = false;
  			this.commentButton = "Kommentera";
  		});
  	} catch (e) {
  		runInAction(() => {
  			this.error = true;
  			this.loading = false;
  		});
  	}
  }

  get filteredIssues() {
  	return this.issues
  		.filter(
  			(issue) => (issue.title
  					.toLowerCase()
  					.includes(this.issueFilter.search.toLowerCase())
            || issue.issueKey
            	.toLowerCase()
            	.includes(this.issueFilter.search.toLowerCase())
            || issue.description
            	.toLowerCase()
            	.includes(this.issueFilter.search.toLowerCase()))
          && this.issueStatusFilter(
          	this.issueFilter.status,
          	issue.status.toLowerCase(),
          )
          && this.applyIssueFilter(
          	this.issueFilter.type,
          	issue.type.toLowerCase(),
          )
          && this.applyIssueFilter(
          	this.issueFilter.area,
          	issue.issueKey.split("-")[0].toLowerCase(),
          )
          && this.onlyAttachmentFilter(
          	this.issueFilter.onlyAttachment,
          	issue.attachmentCount,
          ),
  		)
  		.sort((a, b) => {
  			const sortKey = this.issueFilter.sortBy.value;
  			const dates = ["createDate", "completionDate"];
  			let aValue = a[sortKey];
  			let bValue = b[sortKey];

  			if (dates.includes(sortKey)) {
  				aValue = Date.parse(aValue);
  				bValue = Date.parse(bValue);

  				if (this.issueFilter.sortOrder.value === "asc") {
  					if (bValue > aValue) {
  						return -1;
  					}

  					if (aValue > bValue) {
  						return 1;
  					}
  				}

  				if (this.issueFilter.sortOrder.value === "desc") {
  					if (bValue < aValue) {
  						return -1;
  					}

  					if (aValue < bValue) {
  						return 1;
  					}
  				}
  			}

  			if (sortKey === "issueKey") {
  				aValue = aValue.split("-");
  				bValue = bValue.split("-");

  				if (this.issueFilter.sortOrder.value === "asc") {
  					if (bValue[0] > aValue[0]) {
  						return -1;
  					}

  					if (aValue[0] > bValue[0]) {
  						return 1;
  					}

  					if (aValue[0] === bValue[0]) {
  						if (Number(bValue[1]) > Number(aValue[1])) {
  							return -1;
  						}

  						if (Number(aValue[1]) > Number(bValue[1])) {
  							return 1;
  						}
  					}
  				}

  				if (this.issueFilter.sortOrder.value === "desc") {
  					if (bValue[0] < aValue[0]) {
  						return -1;
  					}

  					if (aValue[0] < bValue[0]) {
  						return 1;
  					}

  					if (aValue[0] === bValue[0]) {
  						if (Number(bValue[1]) < Number(aValue[1])) {
  							return -1;
  						}

  						if (Number(aValue[1]) < Number(bValue[1])) {
  							return 1;
  						}
  					}
  				}
  			}

  			return 0;
  		});
  }

  issueStatusFilter(filter, status) {
  	let statuses = [
  		"klart för stängning",
  		"stängt",
  		"stängt utan åtgärd",
  		"avslås",
  	];

  	if (filter.length === 0) return true;

  	const filters = filter.map((f) => f.value);
  	if (!filters.includes("open") && !statuses.includes(status)) return false;

  	if (filters.includes("preclosed")) {
  		statuses = statuses.filter((s) => s !== "klart för stängning");
  	}

  	if (filters.includes("closed")) {
  		statuses = statuses.filter(
  			(s) => s !== "stängt" && s !== "stängt utan åtgärd" && s !== "avslås",
  		);
  	}

  	if (statuses.includes(status)) return false;

  	return true;
  }

  onlyAttachmentFilter(filter, value) {
  	if (!filter) return true;

  	return value > 0;
  }

  applyIssueFilter(filter, value) {
  	if (filter.length === 0) return true;

  	return filter.map((f) => f.value.toLowerCase()).includes(value);
  }

  getSelectedItems(list, filter) {
  	const filters = filter.map((f) => f.value) || [];

  	return list
  		.map((item) => {
  			if (filters.indexOf(item.value) !== -1) {
  				return item;
  			}

  			return null;
  		})
  		.filter((v) => v !== null);
  }

  updateFilter(filter, value, single = false) {
  	if (single) {
  		this.issueFilter[filter] = value || "";
  	} else {
  		this.issueFilter[filter] = value || [];
  	}

  	this.userStore.updateUser();
  }

  async updateYear(year) {
  	this.issueFilter.year = year;
  	this.userStore.updateUser();
  	await this.getIssues(year);
  }
}

decorate(IssuePageController, {
	error: observable,
	errorMessage: observable,
	loading: observable,

	issues: observable,
	issueType: observable,

	issueAreas: observable,
	issueFilter: observable,
	issueStatuses: observable,
	issueSortBy: observable,
	issueSortOrder: observable,
	filteredIssues: computed,

	getIssue: action,
	getIssues: action,
	getSelectedItems: action,
});
