import {
	computed, decorate, observable, action,
} from "mobx";

import { FormField } from "./FormField";

const extendedTriggers = ["Någon annan", "Elev"];

export class TargetUserField extends FormField {
  extendedValue = "";
  extendedExtraValue = "";
  showExtended = false;
  extendedTrigger = null;

  constructor(formField) {
  	super(formField, "");
  }

  get value() {
  	return this.showExtended ? this.extendedValue : this._value;
  }

  onChange(value) {
  	if (extendedTriggers.findIndex((v) => v === value) > -1) {
  		this.showExtended = true;
  		this.extendedTrigger = value;
  	} else {
  		this.showExtended = false;
  		this.extendedTrigger = null;
  	}

  	super.onChange(value);
  }

  onChangeExtended(value) {
  	this._valid = true;
  	this.extendedValue = value;
  }

  onChangeExtendedExtra(value) {
  	this._valid = true;
  	this.extendedExtraValue = value;
  }

  json() {
  	const prefix = this.extendedTrigger === "Elev" ? "Klass:" : "Tel:";
  	return {
  		id: this.id,
  		value: this.showExtended
  			? `${this.extendedValue} (${prefix} ${this.extendedExtraValue})`
  			: this.value,
  	};
  }
}

decorate(TargetUserField, {
	value: computed,
	onChange: action,

	showExtended: observable,
	extendedValue: observable,
	extendedExtraValue: observable,
});
