import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { observer, inject } from "mobx-react";
import { faUser, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import {
	Page, Footer, Jumbotron, Card,
} from "../common";

export const HomePage = inject(({ rootStore }) => ({
	userStore: rootStore.userStore,
}))(
	observer(({ userStore, history }) => {
		const { isAuthenticated } = userStore;

		return (
			<Page footer={<Footer />}>
				<Jumbotron background="landing">
					<h1 className="welcome-header">Välkommen till SOLTAK Kundservice</h1>
				</Jumbotron>

				<Container className="center">
					<Row className="mb-5">
						{!isAuthenticated ? (
							<>
								<Col md={6}>
									<Card
										title="Anställd"
										description="Inloggning för dig som är anställd hos en kommun eller ett kommunalt bolag som är kund till SOLTAK AB eller för personer med politiska uppdrag. På din kommundator loggas du in automatiskt, på en annan enhet loggar du in med ditt kommunkonto. Användarnamn är din e-postadress, samma lösenord som till din enhet / aa-konto."
										icon={null}
										onClick={() => userStore.login()}
									/>
								</Col>
								<Col md={6}>
									<Card
										title="Privatpersoner, leverantörer och myndigheter"
										description="Möjlighet att lägga ärende till SOLTAK kundservice för privatpersoner, leverantörer och myndigheter"
										icon={null}
										onClick={() => history.push("/external")}
									/>
								</Col>
								<Col md={6}>
									<Card
										title="Elev"
										description="Inloggning för dig som är Elev"
										icon={null}
										onClick={() => userStore.login()}
									/>
								</Col>
							</>
						) : (
							<>
								<Col md={6}>
									<Card
										title="Mina ärenden"
										description="Följ upp dina ärenden"
										icon={faUser}
										onClick={() => history.push("/issues")}
									/>
								</Col>
								<Col md={6}>
									<Card
										title="Registrera ärenden"
										description="Registrera ett nytt ärende"
										icon={faExclamationCircle}
										onClick={() => history.push("/category")}
									/>
								</Col>
							</>
						)}
					</Row>
				</Container>
			</Page>
		);
	}),
);
