import React, { Component } from "react";

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromError() {
		return {
			hasError: true,
		};
	}

	componentDidCatch() {
		this.setState({ hasError: true });
	}

	render() {
		const { hasError } = this.state;
		const { children } = this.props;

		if (hasError) {
			return <h2>Något gick fel! Testa att ladda om sidan.</h2>;
		}

		return children;
	}
}

export default ErrorBoundary;
