export const isUrl = (type) => type === "Url";

export const isDropDown = (type) => type === "DropDown";

export const isDynamicDropDown = (type) => type === "DynamicDropdown";

export const isInfoText = (type) => type === "InfoText";

export const isDocument = (type) => type === "Document";

export const isTargetUser = (type) => type === "TargetUser";

export const isMultiSelect = (type) => type === "MultipleDropDown";

export const isFAQ = (type) => type === "FAQ";
