const minValueLength = 1;

const requiredStringError = "Fältet får inte vara tomt";

const requiredString = (value) => ({
	error: requiredStringError,
	valid: value.length >= minValueLength,
});

const required = (value) => {
	if (typeof value === "string") {
		return requiredString(value);
	}

	return {
		valid: true,
		error: "",
	};
};

const luhn = (value) => {
	const calc = (v) => (v * 2 < 10 ? v * 2 : v * 2 - 9);

	return (
		value
			.split("")
			.map((v, i) => (i % 2 === 0 ? calc(v) : parseInt(v, 10)))
			.reduce((prev, curr) => prev + curr)
      % 10
    === 0
	);
};

const ssnError = "Ogiltigt personnummer";

const ssn = (value) => {
	const regex = /^(19|20)?[0-9]{6}[- ]?[0-9]{4}$/;

	return {
		error: ssnError,
		valid: regex.test(value),
	};
};

const emailError = "Ogiltig Epost";

const email = (value) => {
	const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	return {
		error: emailError,
		valid: regex.test(value),
	};
};

const numberError = "Ogiltigt nummer";

const number = (value) => ({
	error: numberError,
	valid: /^[0-9]*$/.test(value),
});

const phoneError = "Ogiltigt telefonnummer";

const phone = (value) => ({
	error: phoneError,
	valid: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(value),
});

const dateError = "Ogiltigt datum. Måste vara i formatet YYMMDD";

const yymmdd = (value) => {
	const regex = /^((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229)$/;
	return {
		error: dateError,
		valid: regex.test(value),
	};
};

const maxLength = (value, limiter) => ({
	error: `Texten får max vara ${limiter} tecken långt`,
	valid: value.length <= limiter,
});

export const validators = {
	ssn,
	email,
	number,
	phone,
	yymmdd,
	required,
	maxLength,
};
