import React from "react";

import "./Footer.css";

export const Footer = () => (
	<footer className="footer">
		<div className="footer-wrapper">
			<p className="footer-corp">© SOLTAK AB {new Date().getFullYear()}</p>
			<p>
          För mer information,{" "}
				<a
					target="_blank"
					rel="noopener noreferrer"
					href=" https://www.soltakab.se/kontakta-oss/"
				>
					{" "}
            klicka här
				</a>
			</p>
		</div>
	</footer>
);

export default Footer;
