import {
	computed, decorate, observable, action,
} from "mobx";
import { isDocument, isTargetUser } from "../util";
import { FormField } from "./FormField";
import { TargetUserField } from "./TargetUserField";
import { DocumentField } from "./DocumentField";

export class DynamicDropdownField extends FormField {
  showDynamics = false;
  selectedOption = null;
  dynamicFields = [];

  constructor(formField) {
  	super(formField, "");
  	if (formField.dynamicFields.length > 0) {
  		this.dynamicFields = formField.dynamicFields.map((f) => {
  			if (isDocument(f.type)) {
  				return new DocumentField(f);
  			}
  			if (isTargetUser(f.type)) {
  				return new TargetUserField(f);
  			}
  			return new FormField(f, "");
  		});
  	}
  }

  get value() {
  	return this.showDynamics ? this.selectedOption : this._value;
  }

  onChange(value) {
  	if (this.options.includes(value)) {
  		this.showDynamics = true;
  		this.selectedOption = value;
  	} else {
  		this.showDynamics = false;
  		this.selectedOption = null;
  	}

  	super.onChange(value);
  }

  json() {
  	return {
  		id: this.id,
  		value: this.selectedOption,
  	};
  }
}

decorate(DynamicDropdownField, {
	value: computed,
	onChange: action,

	showDynamics: observable,
	selectedOption: observable,
});
