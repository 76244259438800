import React from "react";
import { observer, inject } from "mobx-react";
import { decorate, observable } from "mobx";
import parse from "html-react-parser";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
	Accordion,
	AccordionCollapse,
	Card,
	Badge,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import Attachments from "./Attachments";
import AttachmentUpload from "./AttachmentUpload";
import IssueComments from "./IssueComments";
import Spinner from "./Spinner";
import { SuccessMessage } from "./Alerts";

import "./IssueCard.css";

const IssueCard = observer(
	class extends React.Component {
    issue = null;
    loading = false;
    fileUploadSuccess = false;
	isOpen = false;

    constructor(props) {
    	super(props);

    	this.issue = props.issue;
    }

    solution = (s) => {
    	if (s) {
    		return (
    			<>
    				<h4>Lösning</h4>
    				<p>{parse(s)}</p>
    			</>
    		);
    	}

    	return null;
    };

    parseDate = (date) => new Date(date);

    uploadSuccess() {
    	this.fileUploadSuccess = true;

    	setTimeout(() => {
    		this.fileUploadSuccess = false;
    	}, 2000);
    }

	handleOnClick = () => {
		this.isOpen = !this.isOpen;
	}

    render() {
    	return (
    		<Card>
    			<Accordion.Toggle
					onClick={this.handleOnClick}
	as={Card.Header}
	eventKey={this.issue.issueKey}
	className="d-flex justify-content-between align-items-center pointer"
    			>
    				<OverlayTrigger
	placement="left"
	overlay={(
    						<Tooltip id={`tooltip-${this.issue.issueKey}`}>
    							{this.issue.type}
    						</Tooltip>
    					)}
    				>
    					<div>
    						<FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />{" "}
    						<strong className="mr-2">{this.issue.issueKey}</strong>
    						{this.issue.title}
    					</div>
    				</OverlayTrigger>
    				<div>
    					<Badge variant="primary" className="p-2 mr-5 center">
    						{this.issue.status}
    					</Badge>
    					<span className="center">
    						{this.parseDate(this.issue.createDate).toLocaleDateString(
    							"sv-SE",
    						)}
    					</span>
    				</div>
    			</Accordion.Toggle>
    			<AccordionCollapse eventKey={this.issue.issueKey}>
    				<Card.Body className="py-0">
    					<h4>Ärendebeskrivning</h4>
    					<div className="pb-3">
    						<p>
    							<strong>Ärende skapat:</strong>{" "}
    							{this.parseDate(this.issue.createDate).toLocaleDateString(
    								"sv-SE",
    							)}
    							<br />
    							<strong>Senast uppdaterat:</strong>{" "}
    							{this.parseDate(this.issue.modifiedDate).toLocaleDateString(
    								"sv-SE",
    							)}
    						</p>
    						{parse(this.issue.description)}
    					</div>
    					<h4>Bifogade filer</h4>
    					<div className="pb-3">
    						{this.loading ? (
    							<Spinner />
    						) : (
    							<>
    								<AttachmentUpload
	issueId={this.issue.primaryKey}
	onStartUpload={() => (this.loading = true)}
	onSuccess={(docCount) => {
    										this.issue.attachmentCount += docCount;
    										this.uploadSuccess();
    										this.loading = false;
    									}}
    								/>
    								{this.fileUploadSuccess && (
    									<SuccessMessage text="Filerna har laddats upp!" />
    								)}

    								{this.issue.attachmentCount > 0 && (
    									<Attachments
	issueId={this.issue.primaryKey}
	attachmentCount={this.issue.attachmentCount}
    									/>
    								)}
    							</>
    						)}
    					</div>
    					{this.solution(this.issue.solution)}
    					{this.isOpen && (<IssueComments issue={this.issue} fetch={this.fetchComments} />)}
    				</Card.Body>
    			</AccordionCollapse>
    		</Card>
    	);
    }
	},
);

decorate(IssueCard, {
	issue: observable,
	loading: observable,
	fileUploadSuccess: observable,
	isOpen: observable,
});

export default inject("rootStore")(observer(IssueCard));
