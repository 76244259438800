import React from "react";
import { observer, inject } from "mobx-react";
import {
	Container,
	Row,
	Col,
	Breadcrumb,
	BreadcrumbItem,
	Card,
	Badge,
} from "react-bootstrap";
import parse from "html-react-parser";
import {
	Page,
	Footer,
	SuccessMessage,
	ErrorMessage,
} from "../common";
import Spinner from "../common/Spinner";
import { IssuePageController } from "../../controllers";
import AttachmentUpload from "../common/AttachmentUpload";
import Attachments from "../common/Attachments";
import IssueComments from "../common/IssueComments";

import "./SingleIssuePage.css";

const SingleIssuePage = observer(class extends React.Component {

	constructor(props) {
		super(props);

		this.controller = new IssuePageController(props.rootStore);
		this.issueKey = this.props.match.params.issue;
	}
	async componentDidMount() {
		await this.controller.getIssue(this.issueKey);
	}
	parseDate = (date) => (new Date(date));
	solution = (s) => {
		if (s) {
			return (
				<div className="mb-5">
					<h4>Lösning</h4>
					<p>{ parse(s) }</p>
				</div>
			);
		}
		return null;
	};
	uploadSuccess() {
		this.fileUploadSuccess = true;
		setTimeout(() => { this.fileUploadSuccess = false; }, 2000);
	}

	render() {
		if (this.controller.loading) {
			return (
				<div className="d-flex justify-content-center align-items-center">
					<Spinner />
				</div>
			);
		}

		if (!this.controller.loading && this.controller.error) {
			return (<ErrorMessage text={this.controller.errorMessage} />);
		}

		return (
			<Page footer={<Footer />}>

				<Breadcrumb>
					<BreadcrumbItem href="/issues">Mina ärenden</BreadcrumbItem>
					{this.controller.issue && (
						<BreadcrumbItem>{this.controller.issue.issueKey}</BreadcrumbItem>
					)}
				</Breadcrumb>

				<Container className="my-5">
					<Row>
						<Col md={9}>
							{this.controller.loading && (
								<Row className="justify-content-md-center">
									<Spinner size="lg" />
								</Row>
							)}
							{!this.controller.loading
							&& this.controller.issue && (
								<div>
									<h1>{this.controller.issue.issueKey}: {this.controller.issue.title}</h1>
									<div className="mb-4">
										<Badge variant="primary" className="p-2 mr-2">{ this.controller.issue.status }</Badge>
										{ this.parseDate(this.controller.issue.createDate).toLocaleDateString("sv-SE") }
									</div>
									<h2>Ärendebeskrivning</h2>
									<div className="pb-3">
										<p><strong>Ärende skapat:</strong> { this.parseDate(this.controller.issue.createDate).toLocaleDateString("sv-SE") }</p>
										{ this.controller.issue.description
										&& parse(this.controller.issue.description) }
									</div>
									<h2>Bifogade filer</h2>
									<div className="pb-3">
										{this.loading
											? <Spinner />
											: (
												<>
													<AttachmentUpload
														issueId={this.controller.issue.primaryKey}
														onStartUpload={() => this.loading = true}
														onSuccess={(docCount) => {
															this.controller.issue.attachmentCount += docCount;
															this.uploadSuccess();
															this.loading = false;
														}}
													/>
													{this.fileUploadSuccess && (
														<SuccessMessage text="Filerna har laddats upp!" />
													)}

													{this.controller.issue.attachmentCount > 0
													&& (
														<Attachments
															issueId={this.controller.issue.primaryKey}
															attachmentCount={this.controller.issue.attachmentCount}
														/>
													)}
												</>
											)}
									</div>
									{ this.solution(this.controller.issue.solution) }
									<IssueComments issue={this.controller.issue} />
								</div>
							)}
						</Col>
						<Col md={3}>
							<Card>
								<Card.Title className="p-3 bg-light text-secondary mb-0">FAQ</Card.Title>
								<Card.Body>
									<p>
										<strong>Klart för stängning</strong><br />
										Om ärendet är i status <i>klart för stängning</i>&nbsp;
										kan du lägga till en kommentar för att återöppna ärendet.
									</p>
									<p className="mb-0">
										<strong>Stängt / Stängt utan åtgärd</strong><br />
										Om ärendet är i status <i>Stängt</i>&nbsp;
										eller <i>Stängt utan åtgärd</i>&nbsp;
										kan du inte återöppna ärendet utan får anmäla ett nytt ärende.
									</p>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Page>
		);
	}
});

export default inject("rootStore")(SingleIssuePage);
