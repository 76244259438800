import React from "react";

import {
	faInfo,
	faFolder,
	faAlignLeft,
	faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

import {
	Row,
	Col,
	Container,
} from "react-bootstrap";

import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import {
	Card,
	Jumbotron,
} from "../common";

import {
	chunk,
	isForm,
	isRedirect,
	isParentCategory,
} from "../../util";

export const SubCategoryPage = observer(withRouter(({
	match,
	history,
	isRoot,
	controller,
}) => {

	const category = controller.getCategory(parseInt(match.params.id, 10));

	let items = chunk((category.subCategories || []), 3).map((row, index) => (
		<Row lg={3} md={2} key={index}>

			{row.map((c) => {

				let icon = faInfo;
				let onClick = () => {};

				if (isForm(c)) {
					icon = faAlignLeft;
					onClick = () => history.push(`/category/${c.parentId}/form/${c.categoryId}`);
				} else if (isRedirect(c)) {
					icon = faAlignLeft;
					onClick = () => {

						const redirect = controller.getCategory(c.redirectToId);

						if (redirect && isForm(redirect)) {
							history.push(`/category/${c.parentId}/form/${c.redirectToId}`);
						} else if (redirect && isParentCategory(redirect)) {
							history.push(`/category/${c.redirectToId}`);
						}

					};

				} else if (isParentCategory(c)) {
					icon = faFolder;
					onClick = () => history.push(`/category/${c.categoryId}`);
				}

				return (
					<Col lg={4} md={6} key={c.categoryId}>
						<Card
							title={c.title}
							description={c.description}
							icon={icon}
							onClick={onClick}
							extraInfo={c.extraInfo}
						/>
					</Col>
				);

			})}

		</Row>
	));

	if (items.length < 1) {
		items = (
			<Row>
				<Col md={{ span: 8, offset: 2 }} lg={{ span: 4, offset: 4 }}>
					<Card
						title="Inga kategorier kunde visas"
						description="Ditt konto verkar sakna rättigheter för att kunna visa kategorierna. Vänligen kontakta supporten via telefon 0771-180 810"
						icon={faExclamationCircle}
					/>
				</Col>
			</Row>
		);
	}

	return (
		<Jumbotron background={isRoot ? "main" : "sub"}>
			<Container fluid>
				{items}
			</Container>
		</Jumbotron>

	);

}));
