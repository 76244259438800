import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

export const UnderConstructionPage = () => (
	<Container>
		<div>
			<h4>
					Sidan är under konstruktion. Vi hänvisar i nuläget till IT-frågor.
				<div>
					<Link to="/issues">
							Tillbaka
					</Link>
				</div>
			</h4>
		</div>
	</Container>
);
