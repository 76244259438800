import { action, decorate, observable } from "mobx";

import { FormField } from "./FormField";

const maxFiles = 10;
const maxSize = 20;

export class DocumentField extends FormField {
  files = observable([]);

  constructor(formField) {
  	super(formField, "");
  }

  onAdd(file) {
  	if (file) {
  		const fileSize = file.size / 1024 / 1024;
  		if (fileSize > maxSize) {
  			this._valid = false;
  			this.error = `Filen överskriver gränsen på ${maxSize}MB`;
  		} else if (this.files.length >= maxFiles) {
  			this._valid = false;
  			this.error = `Gränsen på ${maxFiles}st filer har överskridits`;
  		} else {
  			this._valid = true;
  			this.files.push(file);
  		}
  	}
  }

  onRemove(file) {
  	this.files.remove(file);
  }

  validate() {
  	if (this.valid === false) return { valid: false, error: this.error };

  	if (this.required) {
  		const isValid = this.files.length > 0;

  		return {
  			valid: isValid,
  			error: isValid ? "" : "Minst en fil behöver bifogas",
  		};
  	}

  	if (this.files.length > 0) {
  		const fileSize = this.files
  			.map((f) => f.size / 1024 / 1024)
  			.reduce((acc, cur) => acc + cur);

  		if (fileSize > maxSize) {
  			return {
  				valid: false,
  				error: `Gränsen på ${maxSize}MB har överskridits`,
  			};
  		}
  	}

  	return {
  		valid: true,
  		error: "",
  	};
  }
}

decorate(DocumentField, {
	onChange: action,
});
