import React from "react";
import queryString from "query-string";

import { decorate, observable } from "mobx";
import { observer, inject } from "mobx-react";

import { Container, Row } from "react-bootstrap";

import {
	Spinner,
	ErrorMessage,
} from "../common";

const AuthPage = observer(class extends React.Component {

	error = false;

	loading = true;

	async componentDidMount() {
		const params = queryString.parse(this.props.location.search);

		if (!params.code) {
			this.props.history.push("/");
		}

		try {
			const path = await this.props.userStore.auth(params.code);
			this.props.history.push(path);
		} catch (e) {
			this.error = true;
			this.loading = false;

			setTimeout(() => {
				this.props.history.push("/");
			}, 2000);
		}
	}

	render() {
		return (
			<Container>
				<Row className="justify-content-md-center">
					{this.loading && <Spinner size="lg" />}
					{this.error && <ErrorMessage text="Inloggningen misslyckades" />}
				</Row>
			</Container>
		);
	}
});

decorate(AuthPage, {
	error: observable,
	loading: observable,
});

export default inject(({ rootStore }) => ({ userStore: rootStore.userStore }))(AuthPage);
