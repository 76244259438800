import React from "react";

import {
	Modal,
	Button,
} from "react-bootstrap";

export function withConfirmation(WrappedComponent) {
	return class extends React.Component {
		resolve = null;

		constructor(props) {
			super(props);

			this.state = {
				text: "",
				showConfirm: false,
			};
		}

		confirm(text) {
			this.setState({ text, showConfirm: true });

			return new Promise((r) => {
				this.resolve = r;
			});
		}

		onConfirm(confirmed) {
			if (this.resolve) {
				this.resolve(confirmed);
				this.resolve = null;
			}

			this.setState({ showConfirm: false });
		}

		render() {
			const { text, showConfirm } = this.state;

			return (
				<div>
					{showConfirm && <ConfirmationModal onConfirm={(c) => this.onConfirm(c)} text={text} />}
					<WrappedComponent confirm={(t) => this.confirm(t)} {...this.props} />
				</div>
			);
		}
	};
}

const ConfirmationModal = ({ text, onConfirm }) => (
	<Modal show onHide={() => {}}>
		<Modal.Body>
			{text}
		</Modal.Body>
		<Modal.Footer>
			<Button onClick={() => onConfirm(true)}>Godkänn</Button>
			<Button onClick={() => onConfirm(false)}>Avbryt</Button>
		</Modal.Footer>
	</Modal>
);
