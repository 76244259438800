import React from "react";

import { Alert } from "react-bootstrap";

export const SuccessMessage = ({ text }) => (
	<Alert className="text-center" variant="success">
		{text}
	</Alert>
);

export const ErrorMessage = ({ text }) => (
	<Alert className="text-center" variant="danger">
		{text}
	</Alert>
);
