import React from "react";
import { observer } from "mobx-react";
import { decorate, observable } from "mobx";

import {
	Card, Modal, Button, Row, Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import parse from "html-react-parser";

import "./Card.css";

const SolklartCard = observer(
	class extends React.Component {
		constructor(props) {
			super(props);

			this.showModal = false;
		}

    openModal = () => (this.showModal = true);
    closeModal = () => (this.showModal = false);

    render() {
    	return (
    		<Card className="solklart-card">
    			<Card.Body>
    				<Card.Title
	title={this.props.title}
	className="solklart-card-title position-relative"
    				>
    					<Col className="d-inline" onClick={this.props.onClick}>
    						{this.props.title}
    					</Col>
    					{this.props.extraInfo !== undefined
                && this.props.extraInfo !== null
                && this.props.extraInfo !== "" && (
    						<div className="solklart-info-col">
    							<Button
	className="solklart-info-btn"
	onClick={this.openModal}
    							>
    								<FontAwesomeIcon icon={faInfo} />
    							</Button>
    							<Modal show={this.showModal} onHide={this.closeModal}>
    								<Modal.Body>
    									<Row>
    										<Col sm={12}>{this.props.extraInfo}</Col>
    										<Col
	sm={12}
	className="d-flex justify-content-end mb-0 mt-3 py-0"
    										>
    											<Button className="m-0" onClick={this.closeModal}>
                              Stäng
    											</Button>
    										</Col>
    									</Row>
    								</Modal.Body>
    							</Modal>
    						</div>
    					)}
    				</Card.Title>

    				<Card.Text
	onClick={this.props.onClick}
	className="solklart-card-text"
    				>
    					{parse(this.props.description.replace(/(?:\r\n|\r|\n)/g, "<br>"))}
    				</Card.Text>

    				{this.props.icon && (
    					<FontAwesomeIcon
	className="solklart-card-icon"
	icon={this.props.icon}
    					/>
    				)}
    			</Card.Body>
    		</Card>
    	);
    }
	},
);

decorate(SolklartCard, {
	showModal: observable,
});

export default observer(SolklartCard);
