import React from "react";

import { Jumbotron } from "react-bootstrap";

import "./Jumbotron.css";

export default ({ children, background = "" }) => {

	let backgroundClass = "main-background";

	if (background !== "") {
		backgroundClass = background + "-background";
	}

	return (
		<Jumbotron fluid className={`jumbo ${backgroundClass}`}>
			<div className="bg-cover">
				{children}
			</div>
		</Jumbotron>
	);
};
