import {
	toJS,
	action,
	decorate,
	observable,
	runInAction,
	computed,
} from "mobx";

import {
	isFAQ,
	isDocument,
	isInfoText,
	isUrl,
} from "../util";

import { createFormFields } from "../models";

import { formStates } from "./FormController";

export class ExternalFormController {

	id = null;
	title = null;
	issueStore = null;

	faqFields = [];
	formFields = [];
	formState = formStates.none;

	reCaptchaValidated = false;
	siteKey = "6LcNiNUZAAAAAIJzdwjjVXwYBZ6FBobAP3iyCgM0";
	verifyError = false;
	verifyErrorMessage = "Du kunde inte verifieras!";

	constructor(rootStore, id, title, formFields) {

		this.id = id;
		this.title = title;
		this.issueStore = rootStore.issueStore;

		const [filteredFields, faqFields] = formFields.reduce(([fields, faqs], next) => (
			isFAQ(next.type) ? [fields, [...faqs, next]] : [[...fields, next], faqs]
		), [[], []]);

		this.formFields = observable(createFormFields(filteredFields));

		this.faqFields = faqFields.map((f) => ({
			id: f.formId,
			title: f.title,
			description: f.placeholder,
		}));

	}

	validateForm() {
		let isValid = true;

		this.formFields.forEach((f) => {

			if (!f.validate().valid) {
				isValid = false;
				f.onBlur();
			}

		});

		return isValid;
	}

	async validateReCaptcha(token) {
		try {
			const response = await this.issueStore.validateReCaptcha(token);

			if (response.success) {
				runInAction(() => {
					this.reCaptchaValidated = true;
					this.verifyError = false;
				});
			} else {
				runInAction(() => {
					this.reCaptchaValidated = false;
					this.verifyError = true;
				});
			}

		} catch (e) {
			runInAction(() => {
				this.reCaptchaValidated = false;
				this.verifyError = true;
			});
		}
	}

	async onSend() {
		try {

			this.formState = formStates.loading;

			const formFields = this.formFields
				.filter((f) => !isDocument(f.type) && !isInfoText(f.type))
				.map((f) => f.json());

			const id = await this.issueStore.addExternalIssue({
				categoryId: this.id,
				formFields,
			});

			const filePromises = this.formFields
				.filter((f) => isDocument(f.type))
				.map((f) => this.issueStore.addExternalIssueDocument(id, toJS(f.files)));

			await Promise.all(filePromises);

			runInAction(() => this.formState = formStates.success);

			return true;

		} catch (e) {
			runInAction(() => this.formState = formStates.error);

			return false;

		}
	}

	get isLinkOnlyForm() {
		if (this.formFields.length === 1) {
			if (isUrl(this.formFields[0].type)) {
				return true;
			}
		}
		return false;
	}

	get isInfoOnlyForm() {
		let response = true;
		this.formFields.forEach((field) => {
			if (!isUrl(field.type)
        && !isInfoText(field.type)) {
				response = false;
			}
		});
		return response;
	}
}

decorate(ExternalFormController, {
	onSend: action,
	formState: observable,
	formFields: observable,

	reCaptchaValidated: observable,
	verifyError: observable,
	verifyErrorMessage: observable,
	validateReCaptcha: action,
	isLinkOnlyForm: computed,
	isInfoOnlyForm: computed,
});
