import { action, decorate, observable } from "mobx";

export class IssueStore {
	constructor(rootStore) {
		this.rootStore = rootStore;
		this.issues = observable([]);
	}

	async getIssue(issueKey) {
		const response = await this.rootStore.network.getIssue(
			this.rootStore.userStore.getTokenHeader(),
			issueKey,
		);

		this.checkStatus(response.status);

		if (response.status === 204) {
			return 204;
		}

		return response.json();
	}

	async getIssues(year) {
		const response = await this.rootStore.network.getIssues(
			this.rootStore.userStore.getTokenHeader(),
			year,
		);

		this.checkStatus(response.status);

		return response.json();
	}

	async addIssue(issue) {
		const response = await this.rootStore.network.addIssue(
			issue,
			this.rootStore.userStore.getTokenHeader(),
		);

		const body = await response.json();

		this.checkStatus(response.status);

		return body.primaryKey;
	}

	async addExternalIssue(issue) {
		const response = await this.rootStore.network.addExternalIssue(issue);

		const body = await response.json();

		this.checkStatus(response.status);

		return body.primaryKey;
	}

	async addIssueDocument(id, files) {
		const { status } = await this.rootStore.network.addIssueDocument(
			id,
			files,
			this.rootStore.userStore.getTokenHeader(),
		);

		this.checkStatus(status);
	}

	async getIssueDocuments(issueId) {
		const response = await this.rootStore.network.getIssueDocuments(
			issueId,
			this.rootStore.userStore.getTokenHeader(),
		);

		const body = await response.json();

		this.checkStatus(response.status);

		return body;
	}

	async getIssueDocument(document, issueId) {
		await this.rootStore.network.getIssueDocument(
			document.id,
			issueId,
			document.fileName,
			this.rootStore.userStore.getTokenHeader(),
		);
	}

	async addExternalIssueDocument(id, files) {
		const { status } = await this.rootStore.network.addExternalIssueDocument(
			id,
			files,
		);

		this.checkStatus(status);
	}

	async addIssueComment(issueId, comment) {
		const response = await this.rootStore.network.addIssueComment(
			{
				issueId,
				comment,
			},
			this.rootStore.userStore.getTokenHeader(),
		);

		const body = await response.json();

		this.checkStatus(response.status);

		return body;
	}

	async getIssueComments(issueId) {
		const response = await this.rootStore.network.getIssueComments(
			issueId,
			this.rootStore.userStore.getTokenHeader(),
		);

		const body = await response.json();

		this.checkStatus(response.status);

		return body;
	}

	async validateReCaptcha(token) {
		const response = await this.rootStore.network.validateReCaptcha({
			token,
		});
		const body = await response.json();
		return body;
	}

	checkStatus(status) {
		if (status === 401) {
			this.rootStore.userStore.logout();
		}
		let message = "";
		switch (status) {
		case 400:
			message = "Din begäran var inte giltig och kunde inte hanteras.";
			break;
		case 403:
			message = "Du försöker ladda upp en fil till ett ärende som du inte äger.";
			break;
		case 404:
			message = "Kunde inte hitta nödvändig information för att registrera ditt ärende.";
			break;
		case 415:
			message = "Servern mottog begäran i felaktigt format, försök att registrera ditt ärende igen.";
			break;
		case 422:
			message = "Valideringen av ditt ärende misslyckades, försök att registrera ditt ärende igen.";
			break;
		case 500:
			message = "Ett oväntat fel uppstod, försök att registrera ditt ärende igen.";
			break;
		}

		const errorResponse = {
			status,
			message,
		};

		if (status >= 300) {
			throw new Error(JSON.stringify(errorResponse));
		}
	}
}

decorate(IssueStore, {
	issues: observable,
	getIssue: action,
	getIssues: action,

	validateReCaptcha: action,
});
