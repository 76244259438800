import React from "react";
import { Form, ListGroup } from "react-bootstrap";

import "./FormComponents.css";
import { observer } from "mobx-react";
import Select from "react-select";

const getErrorClass = (isInvalid) => (isInvalid ? "error" : "");
const getMaxLength = (validators) => {
	if (validators.length < 1) return false;

	return validators.find((v) => v.name === "maxLength")
		? Number(validators.find((v) => v.name === "maxLength").option)
		: false;
};

const TargetUser = observer(({ formField }) => {
	const options = [];

	if (formField.options.indexOf(formField.placeholder) !== -1) {
		options.push(...formField.options.map((o) => <option key={o}>{o}</option>));
	} else {
		options.push(
			<option disabled key={formField.placeholder}>
				{formField.placeholder}
			</option>,
		);
		options.push(...formField.options.map((o) => <option key={o}>{o}</option>));
	}

	return (
		<>
			<Form.Group>
				<Form.Label>
					{formField.title}
					{formField.required ? " *" : ""}
				</Form.Label>

				<Form.Control
					as="select"
					value={formField._value}
					onChange={(e) => {
						formField.onChange(e.target.value);
						formField.onBlur();
					}}
				>
					{options}
				</Form.Control>

				{!formField.valid && !formField.showExtended && (
					<ErrorLabel message={formField.error} />
				)}
			</Form.Group>

			{formField.showExtended && (
				<div className="ml-4">
					<Form.Group>
						<Form.Label className={getErrorClass(!formField.valid)}>
              AA-konto, E-postadress eller funktion/verksamhet
						</Form.Label>

						<Form.Control
							value={formField.extendedValue}
							isInvalid={!formField.valid}
							onBlur={() => formField.onBlur()}
							onChange={(e) => formField.onChangeExtended(e.target.value)}
						/>

						{!formField.valid && <ErrorLabel message={formField.error} />}
					</Form.Group>
					{formField._value === "Någon annan" && (
						<Form.Group>
							<Form.Label className={getErrorClass(!formField.valid)}>
                Telefonnummer
							</Form.Label>

							<Form.Control
								value={formField.extendedExtraValue}
								isInvalid={!formField.valid}
								onBlur={() => formField.onBlur()}
								onChange={(e) => formField.onChangeExtendedExtra(e.target.value)}
							/>

							{!formField.valid && <ErrorLabel message={formField.error} />}
						</Form.Group>
					)}
					{formField._value === "Elev" && (
						<Form.Group>
							<Form.Label className={getErrorClass(!formField.valid)}>
                Klass
							</Form.Label>

							<Form.Control
								value={formField.extendedExtraValue}
								isInvalid={!formField.valid}
								onBlur={() => formField.onBlur()}
								onChange={(e) => formField.onChangeExtendedExtra(e.target.value)}
							/>

							{!formField.valid && <ErrorLabel message={formField.error} />}
						</Form.Group>
					)}
				</div>
			)}
		</>
	);
});

const DynamicDropdown = observer(({ formField }) => {
	const options = [];

	if (formField.options.indexOf(formField.placeholder) !== -1) {
		options.push(...formField.options.map((o) => <option key={o}>{o}</option>));
	} else {
		options.push(
			<option disabled key={formField.placeholder}>
				{formField.placeholder}
			</option>,
		);
		options.push(...formField.options.map((o) => <option key={o}>{o}</option>));
	}

	return (
		<>
			<Form.Group>
				<Form.Label>
					{formField.title}
					{formField.required ? " *" : ""}
				</Form.Label>

				<Form.Control
					as="select"
					value={formField._value}
					onChange={(e) => {
						formField.onChange(e.target.value);
						formField.onBlur();
					}}
				>
					{options}
				</Form.Control>

				{!formField.valid && !formField.showDynamics && (
					<ErrorLabel message={formField.error} />
				)}
			</Form.Group>

			{formField.showDynamics && (
				<div className="ml-4">
					{formField.dynamicFields
						.filter((df) => formField.selectedOption === df.option)
						.map((df) => {
							const Component = FormComponents[df.type];
							if (Component === undefined) {
								return null;
							}

							return <Component key={df.id} formField={df} />;
						})
						.filter((f) => f !== null)}
				</div>
			)}
		</>
	);
});

const TextInput = observer(({ formField }) => (
	<Form.Group>
		<Form.Label className={getErrorClass(!formField.valid)}>
			{formField.title}
			{formField.required ? " *" : ""}
		</Form.Label>

		<Form.Control
			value={formField.value}
			isInvalid={!formField.valid}
			placeholder={formField.placeholder}
			onBlur={() => formField.onBlur()}
			onChange={(e) => formField.onChange(e.target.value)}
		/>

		{!formField.valid && <ErrorLabel message={formField.error} />}
	</Form.Group>
));

const TextArea = observer(({ formField }) => (
	<Form.Group>
		<Form.Label className={getErrorClass(!formField.valid)}>
			{formField.title}
			{formField.required ? " *" : ""}
		</Form.Label>

		<Form.Control
			as="textarea"
			rows="5"
			value={formField.value}
			isInvalid={!formField.valid}
			placeholder={formField.placeholder}
			onBlur={() => formField.onBlur()}
			onChange={(e) => formField.onChange(e.target.value)}
		/>

		{getMaxLength(formField.validators) && (
			<Form.Text>
				{formField.value.length}/{getMaxLength(formField.validators)}
			</Form.Text>
		)}

		{!formField.valid && <ErrorLabel message={formField.error} />}
	</Form.Group>
));

const DropDown = observer(({ formField }) => {
	const options = [];

	if (formField.options.indexOf(formField.placeholder) !== -1) {
		options.push(...formField.options.map((o) => <option key={o}>{o}</option>));
	} else {
		options.push(
			<option disabled key={formField.placeholder}>
				{formField.placeholder}
			</option>,
		);
		options.push(...formField.options.map((o) => <option key={o}>{o}</option>));
	}

	return (
		<Form.Group>
			<Form.Label className={getErrorClass(!formField.valid)}>
				{formField.title}
				{formField.required ? " *" : ""}
			</Form.Label>

			<Form.Control
				as="select"
				value={formField.value}
				isInvalid={!formField.valid}
				onChange={(e) => {
					formField.onChange(e.target.value);
					formField.onBlur();
				}}
			>
				{options}
			</Form.Control>

			{!formField.valid && <ErrorLabel message={formField.error} />}
		</Form.Group>
	);
});

// Using dirty hacks
const MultipleDropDown = observer(({ formField }) => {
	const values = formField.value !== null ? formField.value.split(",") : [];

	const options = formField.options.map((v) => ({
		value: v,
		label: v,
	}));

	const selectedValues = options
		.map((o) => {
			if (values.indexOf(o.value) !== -1) {
				return o;
			}

			return null;
		})
		.filter((v) => v !== null);

	return (
		<Form.Group>
			<Form.Label className={getErrorClass(!formField.valid)}>
				{formField.title}
				{formField.required ? " *" : ""}
			</Form.Label>

			<Select
				isMulti
				placeholder="Välj"
				options={options}
				value={selectedValues}
				onChange={(newValues) => {
					formField.onChange((newValues || []).map((v) => v.value).join(","));
					formField.onBlur();
				}}
			/>

			{!formField.valid && <ErrorLabel message={formField.error} />}
		</Form.Group>
	);
});

const Checkbox = observer(({ formField }) => (
	<Form.Group>
		<Form.Label className={getErrorClass(!formField.valid)}>
			{formField.title}
			{formField.required ? " *" : ""}
		</Form.Label>

		<Form.Check
			type="checkbox"
			value={formField.value}
			onChange={() => {
				formField.onChange(!formField.value);
				formField.onBlur();
			}}
		/>

		{!formField.valid && <ErrorLabel message={formField.error} />}
	</Form.Group>
));

const InfoText = observer(({ formField }) => (
	<Form.Group>
		<Form.Label>{formField.title}</Form.Label>
	</Form.Group>
));

const Document = observer(({ formField }) => (
	<Form.Group>
		<Form.Label className={getErrorClass(!formField.valid)}>
			{formField.title}
			{formField.required ? " *" : ""}
		</Form.Label>

		<Form.File
			custom
			label="Max 10 filer / 20MB"
			data-browse="Lägg till"
			onChange={(e) => {
				const file = e.target.files[0] ? e.target.files[0] : null;
				formField.onAdd(file);
				formField.onBlur();
			}}
		/>

		<ListGroup>
			{formField.files.map((f, idx) => (
				<ListGroup.Item
					className="file-item"
					action
					key={idx}
					onClick={() => {
						formField.onRemove(f);
						formField.onBlur();
					}}
				>
					{f.name}
				</ListGroup.Item>
			))}
		</ListGroup>

		{!formField.valid && <ErrorLabel message={formField.error} />}
	</Form.Group>
));

const Url = observer(({ formField }) => (
	<Form.Group>
		<a target="_blank" rel="noopener noreferrer" href={formField.placeholder}>
			{formField.title}{" "}
		</a>
	</Form.Group>
));

const ErrorLabel = ({ message }) => (
	<Form.Label className="error error-label">{message}</Form.Label>
);

export const FormComponents = {
	Url,
	InfoText,
	Checkbox,
	TextInput,
	TextArea,
	DropDown,
	DynamicDropdown,
	TargetUser,
	Document,
	MultipleDropDown,
};
