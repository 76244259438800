import React from "react";
import { observer, inject } from "mobx-react";
import { loadReCaptcha } from "react-recaptcha-google";

import {
	Row,
	Breadcrumb,
} from "react-bootstrap";

import { Route } from "react-router-dom";

import { isForm } from "../../util";

import {
	Page,
	Footer,
	Spinner,
} from "../common";

import ExternalFormPage from "./ExternalFormPage";
import { ExternalSubCategoryPage } from "./ExternalSubCategoryPage";
import { ExternalCategoryPageController } from "../../controllers";

const ExternalCategoryPage = observer(class extends React.Component {
	constructor(props) {
		super(props);

		this.controller = new ExternalCategoryPageController(props.rootStore);

	}

	async componentDidMount() {

		loadReCaptcha();
		await this.controller.getCategories();

	}

	onRef(path) {
		const { history } = this.props;
		history.push(path);
	}

	renderContent() {

		if (this.controller.loading) {
			return (
				<Row className="justify-content-md-center">
					<Spinner size="lg" />
				</Row>
			);
		}

		return (
			<>

				<Route
					exact
					path="/external"
					render={() => <ExternalSubCategoryPage isRoot controller={this.controller} />}
				/>

				<Route
					exact
					path="/external/:id"
					render={() => <ExternalSubCategoryPage controller={this.controller} />}
				/>

				<Route
					exact
					path="/external/:id/form/:formId"
					render={() => <ExternalFormPage pageController={this.controller} />}
				/>

			</>
		);

	}

	renderBreadcrumbs() {

		if (this.controller.loading) {
			return null;
		}

		const { pathname } = this.props.location;

		const categoryStack = this.controller.getCategoryStack(pathname);

		const items = categoryStack.map((c) => {

			const path = isForm(c) ? `/external/${c.parentId}/form/${c.categoryId}` : `/external/${c.categoryId}`;

			return (
				<Breadcrumb.Item key={c.title} onClick={() => this.onRef(path)}>
					{c.title}
				</Breadcrumb.Item>
			);

		});

		return (
			<Breadcrumb style={{ marginBottom: "-15px" }}>
				<Breadcrumb.Item onClick={() => this.onRef("/external")}>Registrera ärenden</Breadcrumb.Item>
				{items}
			</Breadcrumb>
		);

	}

	render() {
		return (
			<Page footer={<Footer />}>

				{this.renderBreadcrumbs()}

				{this.renderContent()}

			</Page>
		);
	}
});

export default inject("rootStore")(ExternalCategoryPage);
