import { FormField } from "./FormField";
import { TargetUserField } from "./TargetUserField";
import { DocumentField } from "./DocumentField";

import { isDocument, isDynamicDropDown, isTargetUser } from "../util";
import { DynamicDropdownField } from "./DynamicDropdownField";

export const createFormFields = (formFields) => formFields.map((f) => {
	if (isDocument(f.type)) {
		return new DocumentField(f);
	}

	if (isTargetUser(f.type)) {
		return new TargetUserField(f);
	}

	if (isDynamicDropDown(f.type)) {
		return new DynamicDropdownField(f);
	}

	return new FormField(f, "");
});
