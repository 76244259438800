import React from "react";
import { observer, inject } from "mobx-react";

import {
	faCalendarPlus,
	faCalendarCheck,
	faEnvelope,
} from "@fortawesome/free-regular-svg-icons";
import {
	faSortAmountDown,
	faSortAmountDownAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
	Container,
	Row,
	Col,
	Breadcrumb,
	BreadcrumbItem,
	Accordion,
	Form,
	Card,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";

import Select from "react-select";

import { Page, Footer, Spinner } from "../common";

import { IssuePageController } from "../../controllers";
import IssueCard from "../common/IssueCard";

const IssuePage = observer(
	class extends React.Component {
		constructor(props) {
			super(props);

			this.controller = new IssuePageController(props.rootStore);
		}

		async componentDidMount() {
			await this.controller.getIssues();
		}

		getIconForSortBy(sortBy) {
			switch (sortBy) {
			case "completionDate":
				return <FontAwesomeIcon color="grey" icon={faCalendarCheck} />;
			case "createDate":
				return <FontAwesomeIcon color="grey" icon={faCalendarPlus} />;
			case "issueKey":
				return <FontAwesomeIcon color="grey" icon={faEnvelope} />;
			default:
				return null;
			}
		}

		getIconForSortDirection(direction) {
			switch (direction) {
			case "asc":
				return <FontAwesomeIcon color="grey" icon={faSortAmountDownAlt} />;
			case "desc":
				return <FontAwesomeIcon color="grey" icon={faSortAmountDown} />;
			default:
				return null;
			}
		}

		renderFilter() {
			return (
				<>
					<Row>
						<OverlayTrigger
							placement="top"
							overlay={(
								<Tooltip id="tooltip-year">
									Filtrera på vilket år ärendet senast var modifierat
								</Tooltip>
							)}
						>
							<div className="col-4 mb-3">
								<Select
									placeholder="År"
									options={this.controller.years}
									defaultValue={{
										value: new Date().getFullYear().toString(),
										label: `År ${new Date().getFullYear().toString()}`,
									}}
									onChange={(newValue) => {
										this.controller.updateYear(Number(newValue.value));
									}}
								/>
							</div>
						</OverlayTrigger>

						<div className="col-4 mb-3">
							<Select
								isMulti
								placeholder="Status"
								options={this.controller.issueStatuses}
								value={this.controller.getSelectedItems(
									this.controller.issueStatuses,
									this.controller.issueFilter.status,
								)}
								onChange={(newValues) => {
									this.controller.updateFilter("status", newValues);
								}}
							/>
						</div>

						<div className="col-4 mb-3">
							<Select
								isMulti
								placeholder="Ärendetyp"
								options={this.controller.issueTypes}
								value={this.controller.getSelectedItems(
									this.controller.issueTypes,
									this.controller.issueFilter.type,
								)}
								onChange={(newValues) => {
									this.controller.updateFilter("type", newValues);
								}}
							/>
						</div>

						<div className="col-4 mb-3">
							<Select
								isMulti
								placeholder="Affärsområde"
								options={this.controller.issueAreas}
								value={this.controller.getSelectedItems(
									this.controller.issueAreas,
									this.controller.issueFilter.area,
								)}
								onChange={(newValues) => {
									this.controller.updateFilter("area", newValues);
								}}
							/>
						</div>

						<div className="col-4 mb-3">
							<Select
								placeholder="Sortera efter"
								options={this.controller.issueSortBy}
								value={this.controller.issueFilter.sortBy}
								onChange={(newValue) => {
									this.controller.updateFilter("sortBy", newValue);
								}}
								getOptionLabel={(e) => (
									<div style={{ display: "flex", alignItems: "center" }}>
										{this.getIconForSortBy(e.value)}
										<span style={{ marginLeft: 5, paddingLeft: 3 }}>
											{e.label}
										</span>
									</div>
								)}
							/>
						</div>

						<div className="col-4 mb-3">
							<Select
								placeholder="Sorteringsordning"
								options={this.controller.issueSortOrder}
								value={this.controller.issueFilter.sortOrder}
								onChange={(newValue) => {
									this.controller.updateFilter("sortOrder", newValue);
								}}
								getOptionLabel={(e) => (
									<div style={{ display: "flex", alignItems: "center" }}>
										{this.getIconForSortDirection(e.value)}
										<span style={{ marginLeft: 5, paddingLeft: 3 }}>
											{e.label}
										</span>
									</div>
								)}
							/>
						</div>

						<div className="col-4 mb-3 d-flex align-items-center">
							<Form.Switch
								id="onlyAttachment"
								label="Har bifogad fil"
								checked={this.controller.issueFilter.onlyAttachment}
								onChange={() => {
									const toggle = !this.controller.issueFilter.onlyAttachment;
									this.controller.issueFilter.onlyAttachment = toggle;
								}}
							/>
						</div>

						<div className="col-12 mb-3">
							<Form.Control
								type="text"
								value={this.controller.issueFilter.search}
								placeholder="Sök..."
								onChange={(e) => (this.controller.issueFilter.search = e.target.value)}
							/>
						</div>
					</Row>
				</>
			);
		}

		renderIssues() {
			if (this.controller.loading) {
				return (
					<Row className="justify-content-md-center">
						<Spinner size="lg" />
					</Row>
				);
			}

			if (this.controller.filteredIssues.length === 0) {
				return (
					<Row className="justify-content-md-center">
						<p style={{ fontStyle: "italic" }} className="p-4">
              Inga registrerade ärenden för aktuellt filter
						</p>
					</Row>
				);
			}

			const issues = this.controller.filteredIssues.map((issue) => (
				<IssueCard issue={issue} key={issue.issueKey} />
			));

			return (
				<>
					<Row className="px-2 flex-nowrap">
						<Col md={2} lg={2} className="mr-md-5 mr-lg-0">
              Ärendenummer
						</Col>
						<Col md={4} lg={7}>
              Titel
						</Col>
						<Col md={2} lg={1} className="center">
              Status
						</Col>
						<Col md={2} lg={1} className="center">
              Skapades
						</Col>
					</Row>
					<Accordion>{issues}</Accordion>
				</>
			);
		}

		render() {
			return (
				<Page footer={<Footer />}>
					<Breadcrumb>
						<BreadcrumbItem href="/issues">Mina ärenden</BreadcrumbItem>
					</Breadcrumb>
					<Container className="my-5">
						<Row>
							<Col md={9}>
								{this.renderFilter()}
								{this.renderIssues()}
							</Col>
							<Col md={3}>
								<Card>
									<Card.Title className="p-3 bg-light text-secondary mb-0">
                    FAQ
									</Card.Title>
									<Card.Body>
										<p>
											<strong>Klart för stängning</strong>
											<br />
                      Om ärendet är i status <i>klart för stängning</i>&nbsp;
                      kan du lägga till en kommentar för att återöppna ärendet.
										</p>
										<p className="mb-0">
											<strong>Stängt / Stängt utan åtgärd</strong>
											<br />
                      Om ärendet är i status <i>Stängt</i>&nbsp; eller{" "}
											<i>Stängt utan åtgärd</i>&nbsp; kan du inte återöppna
                      ärendet utan får anmäla ett nytt ärende.
										</p>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Container>
				</Page>
			);
		}
	},
);

export default inject("rootStore")(IssuePage);
