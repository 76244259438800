import React from "react";
import { Route, Redirect } from "react-router-dom";

import { inject, observer } from "mobx-react";

const PrivateRouteInternal = (observer(({ component: Component, userStore, ...rest }) => (

	<Route
		{...rest}
		render={(props) => {
			const path = window.location.pathname;
			if (path !== "/") {
				localStorage.setItem("redirectUri", path);
			}

			return (
				userStore.isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
			);
		}}
	/>

)));

export const PrivateRoute = inject(
	({ rootStore }) => ({ userStore: rootStore.userStore }),
)(PrivateRouteInternal);
