import { UserStore } from "./UserStore";
import { IssueStore } from "./IssueStore";
import { CategoryStore } from "./CategoryStore";

export class RootStore {

	constructor(network) {

		this.network = network;

		this.userStore = new UserStore(this);
		this.categoryStore = new CategoryStore(this);
		this.issueStore = new IssueStore(this);

	}

}
