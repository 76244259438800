import {
	action, decorate, observable, computed, runInAction,
} from "mobx";

export class IssueCommentsController {
	error = false;
	loading = false;

	issueId = null;
	comments = [];

	commentSend = false;
	commentButton = "Kommentera";
	comment = "";

	constructor(rootStore, issue) {
		this.issueStore = rootStore.issueStore;
		this.userStore = rootStore.userStore;

		this.issue = issue;

		if (this.issueStore.issues.length > 0) {
			this.issues = this.issueStore.issues;
		}
	}

	async getComments() {
		this.loading = true;

		try {
			const comments = await this.issueStore.getIssueComments(this.issue.primaryKey);

			if (comments) {
				runInAction(() => {
					this.comments = comments;
					this.loading = false;
					return true;
				});
			}
		} catch (e) {
			runInAction(() => {
				this.error = true;
				this.loading = false;
				return false;
			});
		}
	}

	async postComment() {
		this.commentSend = true;

		try {
			const response = await this.issueStore.addIssueComment(
				this.issue.primaryKey,
				this.comment.trim(),
			);

			if (response) {
				await this.getComments(this.issue.primaryKey);
				this.issue.commentCount = this.comments.length;
			}

			this.comment = " ";
			this.commentSend = false;
		} catch (e) {
			this.commentButton = "Kommentaren kunde inte skickas";
			this.commentSend = false;
		}
	}

	get issueIsClosed() {
		return this.issue.status === "Stängt";
	}

	setComment(value) {
		this.comment = value;
	}
}

decorate(IssueCommentsController, {
	error: observable,
	loading: observable,
	comment: observable,

	issueId: observable,

	commentSend: observable,
	commentButton: observable,
	comments: observable,

	getComments: action,

	issueIsClosed: computed,
});
