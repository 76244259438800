import React from "react";
import { Spinner } from "react-bootstrap";

export default ({ size = "sm" }) => (
	<Spinner
		style={{ marginTop: 20 }}
		animation="border"
		size={size}
		role="status"
	>
		<span className="sr-only">Loading...</span>
	</Spinner>
);
