import React from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import parse from "html-react-parser";

import {
	Row,
	Col,
	Form,
	Card,
	Button,
	Container,
	ListGroup,
} from "react-bootstrap";

import { FormComponents } from "../common/FormComponents";
import { formStates, FormController } from "../../controllers";
import { withConfirmation } from "../../hoc/withConfirmation";

const buttonState = {
	[formStates.none]: "primary",
	[formStates.error]: "danger",
	[formStates.loading]: "primary",
	[formStates.success]: "success",
};

const buttonText = {
	[formStates.none]: "Skicka",
	[formStates.error]: "Något gick fel",
	[formStates.loading]: "Laddar...",
	[formStates.success]: "Ditt ärende är nu registrerat",
};

class FormPage extends React.Component {
	constructor(props) {
		super(props);

		const category = props.pageController.getCategory(
			parseInt(props.match.params.formId, 10),
		);

		this.controller = new FormController(
			props.rootStore,
			category.categoryId,
			category.title,
			category.formFields,
		);

		if (this.controller.isLinkOnlyForm) {
			const win = window.open(
				this.controller.formFields[0].placeholder,
				"_blank",
			);
			win.focus();
		}
	}

	async onSend() {
		if (this.controller.validateForm()) {
			const confirmed = await this.props.confirm(`
				Är du säker på att du vill skicka in ditt ärende?
			`);

			if (confirmed) {
				const success = await this.controller.onSend();

				if (success) {
					setTimeout(() => this.props.history.push("/issues"), 1500);
				}
			}
		}
	}

	getFields() {
		return this.controller.formFields
			.map((f) => {
				const Component = FormComponents[f.type];

				if (Component === undefined) {
					return null;
				}

				return <Component key={f.id} formField={f} />;
			})
			.filter((f) => f !== null);
	}

	hasFAQ() {
		return (
			Array.isArray(this.controller.faqFields)
      && this.controller.faqFields.length > 0
		);
	}

	renderFAQ() {
		const { faqFields } = this.controller;

		if (this.hasFAQ()) {
			const items = faqFields.map((f) => (
				<ListGroup.Item key={f.id}>
					<Card.Title>{f.title}</Card.Title>
					<Card.Text>{parse(f.description)}</Card.Text>
				</ListGroup.Item>
			));

			return (
				<Card>
					<Card.Header>FAQ</Card.Header>

					<ListGroup variant="flush">{items}</ListGroup>
				</Card>
			);
		}

		return null;
	}

	render() {
		const text = buttonText[this.controller.formState];
		const variant = buttonState[this.controller.formState];

		return (
			<Container style={{ margin: "60px auto" }}>
				<Row>
					<Col xs={this.hasFAQ() ? 8 : 12}>
						<Form className="form">
							<h1>{this.controller.title}</h1>

							{this.getFields()}

							{!this.controller.isLinkOnlyForm
                && !this.controller.isInfoOnlyForm && (
								<>
									<Button variant={variant} onClick={() => this.onSend()}>
										{text}
									</Button>
									<p className="error-message">
										{this.controller.formErrorMessage}
									</p>
								</>
							)}
						</Form>
					</Col>
					<Col>{this.renderFAQ()}</Col>
				</Row>
			</Container>
		);
	}
}

export default inject("rootStore")(
	withConfirmation(withRouter(observer(FormPage))),
);
