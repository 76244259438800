import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";

import {
	Button, Form, ListGroup, ListGroupItem,
} from "react-bootstrap";

import { ErrorMessage } from "./Alerts";

import { AttachmentController } from "../../controllers";

const AttachmentUpload = observer(
	class extends React.Component {
		constructor(props) {
			super(props);

			this.controller = new AttachmentController(props.rootStore);
			this.issueId = props.issueId;
		}

		async handleSumbit() {
			this.props.onStartUpload();
			const status = await this.controller.addNewIssueDocuments(this.issueId);

			if (status) {
				this.props.onSuccess(this.controller.documents.length);
				this.controller.documents = [];
			}
		}

    checkFileSize = () => {
    	const fileSizes = this.controller.documents?.map(
        (f) => f.size / 1024 / 1024
      );
    	const fileSize = fileSizes.length > 0 ? fileSizes?.reduce((acc, cur) => acc + cur) : 0;

    	if (fileSize > 20) {
    		this.controller.valid = false;

    		return false;
    	}

    	return true;
    };

    render() {
    	return (
    		<Form onSubmit={() => this.handleSumbit()}>
    			<Form.Group>
    				<Form.File
	custom
	label="Max 10 filer / 20MB"
	data-browse="Lägg till"
	onChange={(e) => {
    						const file = e.target.files[0] ? e.target.files[0] : null;
    						if (file && this.controller.documents.length < 10) {
    							this.controller.documents.push(file);
    						}
    					}}
    				/>

    				<ListGroup>
    					{this.controller.documents.map((doc, index) => (
    						<ListGroupItem
	key={index}
	className="file-item"
	action
	onClick={() => this.controller.documents.remove(doc)}
    						>
    							{doc.name}
    						</ListGroupItem>
    					))}
    				</ListGroup>
    			</Form.Group>
    			{this.controller.documents.length > 0 && this.checkFileSize() && (
    				<Form.Group>
    					<Button
	onClick={() => this.handleSumbit()}
	disabled={this.controller.loading}
    					>
                Ladda upp
    					</Button>
    				</Form.Group>
    			)}
    			{this.controller.error && (
    				<ErrorMessage text="Något gick fel med uppladdningen, verifiera max antal filer och storlek. Försök sedan igen." />
    			)}
    			{!this.checkFileSize() && (
    				<ErrorMessage text="Max storleken på filerna har överskridits" />
    			)}
    		</Form>
    	);
    }
	},
);

export default inject("rootStore")(withRouter(AttachmentUpload));
