import React, { useEffect } from "react";
import "./App.css";

import { BrowserRouter as Router, Route } from "react-router-dom";
import { inject, observer } from "mobx-react";

import {
	AuthPage,
	HomePage,
	CategoryPage,
	IssuePage,
	UnderConstructionPage,
	ExternalCategoryPage,
	SingleIssuePage,
} from "./components/pages";

import { NavBar } from "./NavBar";
import { PrivateRoute, ErrorBoundary } from "./components/common";

export const App = inject(({ rootStore }) => ({
	userStore: rootStore.userStore,
}))(
	observer(({ userStore }) => {
		useEffect(() => {
			const verify = async () => {
				await userStore.verifyToken();
			};

			verify();
		}, [userStore]);

		return (
			<Router>
				<NavBar />

				<Route exact path="/" component={HomePage} />

				<Route exact path="/auth" component={AuthPage} />

				<ErrorBoundary>
					<Route
						path="/external"
						component={(props) => <ExternalCategoryPage {...props} />}
					/>

					<PrivateRoute
						path="/category"
						component={(props) => <CategoryPage {...props} />}
					/>

					<PrivateRoute
						path="/issues"
						exact
						component={(props) => <IssuePage {...props} />}
					/>

					<PrivateRoute
						path="/issues/:issue"
						exact
						component={(props) => <SingleIssuePage {...props} />}
					/>

					<PrivateRoute
						path="/construction"
						component={UnderConstructionPage}
					/>
				</ErrorBoundary>
			</Router>
		);
	}),
);
