import React from "react";

import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
	Nav,
	Button,
	Navbar,
	NavItem,
	Container,
	NavbarBrand,
} from "react-bootstrap";

import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Logo from "./assets/logo.svg";

import { Spinner } from "./components/common";

import "./NavBar.css";

const NabBarInternal = observer(({ userStore, history }) => {

	const {
		user,
		loading,
		isAuthenticated,
	} = userStore;

	const onRef = (path) => {
		history.push(path);
	};

	return (
		<div>
			<Navbar expand="sm" fixed="sticky">
				<Container>

					<NavbarBrand style={{ cursor: "pointer" }} onClick={() => onRef("/")}>
						<img src={Logo} height="60" width="100" alt="Solklart" />
					</NavbarBrand>

					<Nav className="mr-auto" navbar />

					<Nav className="justify-content-end" navbar>

						{isAuthenticated ? (
							<>

								<NavItem>
									<Button onClick={() => onRef("/issues")} color="primary" className="button">
										Mina ärenden
									</Button>
								</NavItem>

								<NavItem>
									<Button onClick={() => onRef("/category")} color="primary" className="button">
										Registrera ärenden
									</Button>
								</NavItem>

								<NavItem>
									<Button color="primary" className="button" onClick={() => userStore.logout()}>
										Logga ut
									</Button>

								</NavItem>

							</>
						) : (
							<NavItem>
								<Button color="primary" className="button" onClick={() => userStore.login()}>
									{loading ? <Spinner /> : "Logga in"}
								</Button>
							</NavItem>
						)}

					</Nav>

				</Container>

				{user !== null && (
					<label className="username">
						<FontAwesomeIcon icon={faUser} />
						{" "}
						{user.name}
					</label>
				)}

			</Navbar>
		</div>
	);
});

export const NavBar = inject(
	({ rootStore }) => ({ userStore: rootStore.userStore }),
)(withRouter(NabBarInternal));
