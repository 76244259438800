import {
	action,
	decorate,
	observable,
} from "mobx";

export class CategoryStore {

	categories = [];
	externalCategories = [];

	constructor(rootStore) {
		this.rootStore = rootStore;
		this.categories = observable([]);
	}

	async getCategories() {

		if (this.categories.length > 0) {
			return Promise.resolve(this.categories);
		}

		const response = await this.rootStore.network.getCategories(
			this.rootStore.userStore.getTokenHeader(),
		);

		if (response.status === 401) {
			this.rootStore.userStore.logout();
			return [];
		}

		this.categories = await response.json();

		return this.categories;
	}

	async getExternalCategories() {

		if (this.externalCategories.length > 0) {
			return Promise.resolve(this.externalCategories);
		}

		const response = await this.rootStore.network.getExternalCategories();

		this.externalCategories = await response.json();

		return this.externalCategories;
	}
}

decorate(CategoryStore, {
	categories: observable,
	externalCategories: observable,
	getCategories: action,
	getExternalCategories: action,
});
