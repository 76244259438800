import fileDownload from "js-file-download";

export class Network {
	auth(code, queryStrings) {
		return fetch(`/api/v1/auth?code=${code}${queryStrings}`, {
			method: "GET",
		}).then((r) => r.json());
	}

	getLoginUrl() {
		return fetch("/api/v1/auth/login", { method: "GET" }).then((r) => r.json());
	}

	async getCategories(tokenHeader) {
		return fetch("/api/v1/category", {
			method: "GET",
			headers: {
				...tokenHeader,
			},
		});
	}

	async getExternalCategories() {
		return fetch("/api/v1/category/external", {
			method: "GET",
		});
	}

	/**
   * Get a specific issue for logged in user
   * @param tokenHeader
   * @param issueKey
   * @returns {Promise<Response>}
   */
	async getIssue(tokenHeader, issueKey) {
		return fetch(`/api/v1/issue/${issueKey}`, {
			method: "GET",
			headers: {
				...tokenHeader,
			},
		});
	}

	/**
   * Get all issues for logged in user
   *
   * @param {*} tokenHeader
   */
	async getIssues(tokenHeader, year) {
		return fetch(
			`/api/v1/issue?year=${year > 0 ? year : new Date().getFullYear()}`,
			{
				method: "GET",
				headers: {
					...tokenHeader,
				},
			},
		);
	}

	addIssue(issue, tokenHeader) {
		return fetch("/api/v1/issue", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				...tokenHeader,
			},
			body: JSON.stringify(issue),
		});
	}

	addExternalIssue(issue) {
		return fetch("/api/v1/issue/external", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(issue),
		});
	}

	addIssueDocument(id, files, tokenHeader) {
		if (Array.isArray(files) && files.length > 0) {
			const formData = new FormData();

			for (let i = 0; i < files.length; i++) {
				formData.append(files[i].name, files[i]);
			}

			return fetch("/api/v1/attachment/" + id, {
				method: "POST",
				headers: {
					...tokenHeader,
				},
				body: formData,
			});
		}

		return Promise.resolve({ status: 200 });
	}

	getIssueDocuments(issueId, tokenHeader) {
		return fetch(`/api/v1/attachment/${issueId}`, {
			method: "GET",
			headers: {
				...tokenHeader,
			},
		});
	}

	getIssueDocument(id, issueId, filename, tokenHeader) {
		return fetch(`/api/v1/attachment/${issueId}/${id}`, {
			method: "GET",
			headers: {
				...tokenHeader,
			},
		})
			.then((response) => response.blob())
			.then((body) => fileDownload(body, filename));
	}

	addExternalIssueDocument(id, files) {
		if (Array.isArray(files) && files.length > 0) {
			const formData = new FormData();

			for (let i = 0; i < files.length; i++) {
				formData.append(files[i].name, files[i]);
			}

			return fetch(`/api/v1/attachment/${id}/external`, {
				method: "POST",
				body: formData,
			});
		}

		return Promise.resolve({ status: 200 });
	}

	addIssueComment(comment, tokenHeader) {
		return fetch("/api/v1/issue/comment", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				...tokenHeader,
			},
			body: JSON.stringify(comment),
		});
	}

	getIssueComments(issueId, tokenHeader) {
		return fetch(`/api/v1/issue/comment/${issueId}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				...tokenHeader,
			},
		});
	}

	validateReCaptcha(reCaptcha) {
		return fetch("/api/v1/recaptcha", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(reCaptcha),
		});
	}

	verifyUserToken(tokenHeader) {
		return fetch("/api/v1/auth/verify", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				...tokenHeader,
			},
		});
	}
}
