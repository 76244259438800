import React from "react";

import "./Page.css";

// Page is a cointainer that should implement shared logic
export const Page = ({ children, footer = null }) => (
	<>
		<div className="page">
			{children}
		</div>

		{footer}
	</>
);
